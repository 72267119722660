import { useInstance } from '../../hooks/useInstance';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from './TailwindTransition';

interface ArrowBaseProps {
  width?: number;
  backgroundColor?: string;
  borderWidth?: number;
  borderColor?: string;
  location?: string;
}

function ArrowLeft(props: ArrowBaseProps): JSX.Element {
  const {
    width = 0,
    borderWidth = 0,
    backgroundColor,
    borderColor,
    location = '50%',
  } = props;
  return (
    <div
      style={{
        width: width,
        height: width * 2,
        overflow: 'hidden',
        marginRight: -borderWidth,
        marginTop: -width,
        position: 'absolute',
        right: '100%',
        top: location,
      }}
      className={'flex justify-center items-center'}
    >
      <div
        style={{
          width: '100%',
          height: '50%',
          backgroundColor: backgroundColor,
          borderLeftWidth: borderWidth,
          borderBottomWidth: borderWidth,
          borderStyle: 'solid',
          borderColor: borderColor,
          transform: `translateX(${width / 2}px) rotate(45deg)`,
        }}
      ></div>
    </div>
  );
}

function ArrowRight(props: ArrowBaseProps): JSX.Element {
  const {
    width = 0,
    borderWidth = 0,
    backgroundColor,
    borderColor,
    location = '50%',
  } = props;

  return (
    <div
      style={{
        width: width,
        height: width * 2,
        overflow: 'hidden',
        marginLeft: -borderWidth,
        marginTop: -width,
        position: 'absolute',
        left: '100%',
        top: location,
      }}
      className={'flex justify-center items-center'}
    >
      <div
        style={{
          width: '100%',
          height: '50%',
          backgroundColor: backgroundColor,
          borderTopWidth: borderWidth,
          borderRightWidth: borderWidth,
          borderStyle: 'solid',
          borderColor: borderColor,
          transform: `translateX(${-width / 2}px) rotate(45deg)`,
        }}
      ></div>
    </div>
  );
}

function ArrowTop(props: ArrowBaseProps): JSX.Element {
  const {
    width = 0,
    borderWidth = 0,
    backgroundColor,
    borderColor,
    location = '50%',
  } = props;

  return (
    <div
      style={{
        width: width * 2,
        height: width,
        overflow: 'hidden',
        marginBottom: -borderWidth,
        marginLeft: -width,
        position: 'absolute',
        bottom: '100%',
        left: location,
      }}
      className={'flex justify-center items-center'}
    >
      <div
        style={{
          width: '50%',
          height: '100%',
          backgroundColor: backgroundColor,
          borderTopWidth: borderWidth,
          borderLeftWidth: borderWidth,
          borderStyle: 'solid',
          borderColor: borderColor,
          transform: `translateY(${width / 2}px) rotate(45deg)`,
        }}
      ></div>
    </div>
  );
}

function ArrowBottom(props: ArrowBaseProps): JSX.Element {
  const {
    width = 0,
    borderWidth = 0,
    backgroundColor,
    borderColor,
    location = '50%',
  } = props;

  return (
    <div
      style={{
        width: width * 2,
        height: width,
        overflow: 'hidden',
        marginTop: -borderWidth,
        marginLeft: -width,
        position: 'absolute',
        top: '100%',
        left: location,
      }}
      className={'flex justify-center items-center'}
    >
      <div
        style={{
          width: '50%',
          height: '100%',
          backgroundColor: backgroundColor,
          borderBottomWidth: borderWidth,
          borderRightWidth: borderWidth,
          borderStyle: 'solid',
          borderColor: borderColor,
          transform: `translateY(${-width / 2}px) rotate(45deg)`,
        }}
      ></div>
    </div>
  );
}

interface ArrowProps extends ArrowBaseProps {
  tooltipPosition: TooltipPosition;
}

function Arrow(props: ArrowProps) {
  switch (props.tooltipPosition) {
    case 'top':
      return <ArrowBottom {...props} />;
    case 'left':
      return <ArrowRight {...props} />;
    case 'bottom':
      return <ArrowTop {...props} />;
    case 'right':
      return <ArrowLeft {...props} />;
    default:
      return null;
  }
}

function Content(props: {
  width?: number;
  height?: number;
  backgroundColor?: string;
  borderWidth?: number;
  borderColor?: string;
  borderRadius?: number;
  children?: React.ReactNode;
}): JSX.Element {
  const {
    width,
    height,
    borderWidth,
    borderColor,
    borderRadius,
    backgroundColor,
    children,
  } = props;
  return (
    <div
      style={{
        width: width,
        height: height,
        background: backgroundColor,
        borderWidth: borderWidth,
        borderColor: borderColor,
        borderStyle: 'solid',
        borderRadius: borderRadius,
      }}
    >
      {children}
    </div>
  );
}

function getMargin(
  position: TooltipPosition,
  arrowWidth?: number
): React.CSSProperties | null {
  switch (position) {
    case 'top':
      return { marginBottom: arrowWidth };
    case 'left':
      return { marginRight: arrowWidth };
    case 'bottom':
      return { marginTop: arrowWidth };
    case 'right':
      return { marginLeft: arrowWidth };
    default:
      return null;
  }
}

export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

export interface TooltipProps {
  position: TooltipPosition;
  contentWidth?: number;
  contentHeight?: number;

  // leave it black or assign 0 if arrow is not required.
  arrowWidth?: number;

  // default: 50% for center location.
  arrowLocation?: `${number}%` | `${number}px`;

  backgroundColor?: string;
  borderWidth?: number;
  borderColor?: string;
  borderRadius?: number;
  filter?: string;
  fadeInDurationMS?: number;
  children?: React.ReactNode;
}

export function Tooltip(props: TooltipProps): JSX.Element {
  const {
    contentWidth,
    contentHeight,
    arrowWidth,
    arrowLocation,
    position,
    backgroundColor,
    borderWidth,
    borderColor,
    borderRadius,
    filter,
    fadeInDurationMS = 500,
    children,
  } = props;

  const stages = useInstance<TailwindTransitionStage<Classes>[]>(() => {
    return [{ classes: 'opacity-0' }, { classes: 'opacity-100' }];
  });

  const marginStyle = getMargin(position, arrowWidth);
  return (
    <StagedTailwindTransition stages={stages}>
      {(ref, initial) => (
        <div
          className={`relative flex justify-center items-center ${initial}`}
          ref={ref}
          style={{
            ...marginStyle,
            filter: filter,
            transition: fadeInDurationMS
              ? `opacity ${fadeInDurationMS}ms`
              : undefined,
          }}
        >
          <Content
            width={contentWidth}
            height={contentHeight}
            backgroundColor={backgroundColor}
            borderWidth={borderWidth}
            borderColor={borderColor}
            borderRadius={borderRadius}
          >
            {children}
          </Content>

          <Arrow
            tooltipPosition={position}
            width={arrowWidth}
            borderWidth={borderWidth}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            location={arrowLocation}
          />
        </div>
      )}
    </StagedTailwindTransition>
  );
}
